import React from 'react'
import PropTypes from 'prop-types'
import Nav from './Nav'
import coinfundLogo from "../images/coinfund.png"

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <span className="icon fa-envira"></span>
        </div>
        <div className="content">
            <div className="inner">
                <h1>Grassfed Network</h1>
                <p>Grassfed deploys cryptoeconomic strategies across decentralized networks</p>

                <p className="byline">powered by</p>
                <a href="http://coinfund.io"><img id="coinfund-logo" alt="coinfund" src={coinfundLogo} /></a>

                <p id="banner"><i className="icon fa-medium"></i><a href="https://medium.com/@coinfund_io/announcing-grassfed-network-and-decred-staking-pool-with-placeholder-55a32a312710">Read the blog post</a></p>
            </div>
        </div>
        <Nav />
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
